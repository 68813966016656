import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

import airbridge from 'airbridge-web-sdk-loader';
import clevertap from 'clevertap-web-sdk';
import mixpanel from 'mixpanel-browser';
import TiktokPixel from 'tiktok-pixel';

import { ClevertapJSONFormatter } from '@/formatters/ClevertapJSONFormatter';
import { PartnerPerk } from '@/models/PartnerPerks';
import { ScheduleTaskModel } from '@/models/ReleaseCycles';
import { StripeProductModel } from '@/models/Stripe';
import { CustomAirbridgeEventModel, ProFeatureLabel } from '@/models/UserTracking';

import { AccountModel } from '../models/Account';

class UserTracking {
  account: AccountModel;

  constructor({ account }: { account: AccountModel }) {
    this.account = account;
    this.connectUser();
  }

  connectUser = () => {
    airbridge.setUserID(this.account.id);
    airbridge.setUserEmail(this.account.contact.email);
    mixpanel?.identify(this.account.id);
    mixpanel?.people.set({
      $email: this.account.contact.email,
      $name: this.account.contact.firstName + ' ' + this.account.contact.lastName,
      $phone: this.account.contact.phone,
      $countryCode: this.account.countryCode,
      $isSubscribed: this.account.subscription?.isActive,
    });
    clevertap.profile.push(
      ClevertapJSONFormatter({
        site: {
          email: this.account.contact.email,
          firstName: this.account.contact.firstName,
          lastName: this.account.contact.lastName,
          name: this.account.contact.firstName + ' ' + this.account.contact.lastName,
          phone: this.account.contact.phone,
          identity: this.account.id,
          countryCode: this.account.countryCode,
          isSubscribed: this.account.subscription?.isActive,
          subscriptionStatus: this.account.subscription?.status,
          subscriptionPlatform: this.account.subscription?.platform,
          subscriptionBillingType: this.account.subscription?.billingType,
        },
      })
    );
    clevertap.privacy.push({ optOut: false });
    clevertap.privacy.push({ useIP: true });
  };

  userSignUp = () => {
    airbridge.events.send('airbridge.user.signup');
    ReactPixel.track('CompleteRegistration');
    TiktokPixel.track('CompleteRegistration', {});
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'User',
      eventAction: 'Sign Up',
    });
    clevertap.event.push('Sign Up');
    mixpanel.track('Sign Up');
    if (window.tolt_referral) {
      window.tolt.signup(this.account.contact.email);
    }
  };

  userLogin = () => {
    airbridge.events.send('airbridge.user.signin');
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'User',
      eventAction: 'Log In',
    });
    clevertap.onUserLogin.push(
      ClevertapJSONFormatter({
        site: {
          email: this.account.contact.email,
          name: this.account.contact.firstName + ' ' + this.account.contact.lastName,
          firstName: this.account.contact.firstName,
          lastName: this.account.contact.lastName,
          phone: this.account.contact.phone,
          identity: this.account.id,
          countryCode: this.account.countryCode,
        },
      })
    );
    clevertap.event.push('Log In');
    mixpanel.track('Log In');
  };

  updateProfile = (data: object) => {
    clevertap.onUserLogin.push(
      ClevertapJSONFormatter({
        site: data,
      })
    );
  };

  userLogout = () => {
    airbridge.events.send('airbridge.user.signout');
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'User',
      eventAction: 'Log Out',
    });
    clevertap.event.push('Log Out');
    mixpanel.track('Log Out');
  };

  userHomeScreenViewed = () => {
    airbridge.events.send('airbridge.ecommerce.home.viewed');
    ReactPixel.trackCustom('HomePageView');
    TiktokPixel.track('HomePageView', {});
    clevertap.event.push('Home Screen Viewed');
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'User',
      eventAction: 'Home Screen Viewed',
    });
    mixpanel.track('Home Screen Viewed');
  };

  userInitiatedCheckout = (product: StripeProductModel, isSubscribed?: boolean) => {
    airbridge.events.send('airbridge.initiateCheckout', {
      label: isSubscribed ? 'Playlisting Discounted' : product.productType,
      action: 'Checkout Initiated',
      semanticAttributes: {
        totalValue: product.orderDetails.totalValue,
        totalQuantity: product.orderDetails.totalQuantity,
        currency: product.orderDetails.currency,
        products: product.orderDetails.products,
      },
    });
    ReactPixel.track('InitiateCheckout', {
      value: product.orderDetails.totalValue,
      currency: product.orderDetails.currency,
      contents: product.orderDetails.products,
    });
    TiktokPixel.track('InitiateCheckout', {
      value: product.orderDetails.totalValue,
      currency: product.orderDetails.currency,
      contents: product.orderDetails.products,
    });
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'User',
      eventAction: 'InitiateCheckout',
      event_label: product.productType,
      value: product.orderDetails.totalValue,
      data: {
        totalValue: product.orderDetails.totalValue,
        totalQuantity: product.orderDetails.totalQuantity,
        currency: product.orderDetails.currency,
        products: product.orderDetails.products,
      },
    });
    clevertap.event.push(
      'Initiate Checkout',
      ClevertapJSONFormatter({
        value: product.orderDetails.totalValue,
        paymentMode: 'Stripe',
        chargedID: product.draftCampaignId,
        product: product.productType,
        productID: product.orderDetails.products[0].productID,
        quantity: product.orderDetails.totalQuantity,
      })
    );
    mixpanel.track('Initiate Checkout', {
      value: product.orderDetails.totalValue,
      paymentMode: 'Stripe',
      chargedID: product.draftCampaignId,
      product: product.productType,
      productID: product.orderDetails.products[0].productID,
      quantity: product.orderDetails.totalQuantity,
      platform: 'Web',
    });
  };

  userCompletedCheckout = (product: StripeProductModel, sessionId: string) => {
    if (product.productType === 'Subscription') {
      return ReactGA.send({
        hitType: 'event',
        eventCategory: 'User',
        eventAction: 'Trial Started',
        event_label: product.productType,
        value: product.orderDetails.totalValue,
        totalValue: product.orderDetails.totalValue,
        totalQuantity: product.orderDetails.totalQuantity,
        currency: product.orderDetails.currency,
      });
    }
    ReactGA.event('purchase', {
      transaction_id: sessionId,
      value: product.orderDetails.totalValue,
      currency: product.orderDetails.currency,
      items: product.orderDetails.products.map((product) => ({
        id: product.productID,
        name: product.name,
        quantity: product.quantity,
        price: product.price,
      })),
    });
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'User',
      eventAction: 'Order Completed',
      event_label: product.productType,
      value: product.orderDetails.totalValue,
      totalValue: product.orderDetails.totalValue,
      totalQuantity: product.orderDetails.totalQuantity,
      currency: product.orderDetails.currency,
    });
  };

  userCancelledCheckout = (product: StripeProductModel, isSubscribed?: boolean) => {
    airbridge.events.send('airbridge.ecommerce.order.canceled', {
      label: isSubscribed ? 'Playlisting Discounted' : product.productType,
      action: 'Order Cancelled',
      semanticAttributes: {
        totalValue: product.orderDetails.totalValue,
        totalQuantity: product.orderDetails.totalQuantity,
        currency: product.orderDetails.currency,
        products: product.orderDetails.products,
      },
    });
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'User',
      eventAction: 'Order Cancelled',
      event_label: product.productType,
      value: product.orderDetails.totalValue,
      data: {
        totalValue: product.orderDetails.totalValue,
        totalQuantity: product.orderDetails.totalQuantity,
        currency: product.orderDetails.currency,
        products: product.orderDetails.products,
      },
    });
    ReactPixel.track('Order Cancelled', {
      value: product.orderDetails.totalValue,
      currency: product.orderDetails.currency,
      contents: product.orderDetails.products,
    });
    TiktokPixel.track('Order Cancelled', {
      value: product.orderDetails.totalValue,
      currency: product.orderDetails.currency,
      contents: product.orderDetails.products,
    });
    clevertap.event.push(
      'Order Cancelled',
      ClevertapJSONFormatter({
        value: product.orderDetails.totalValue,
        paymentMode: 'Stripe',
        chargedID: product.draftCampaignId,
        product: product.productType,
        productID: product.orderDetails.products[0].productID,
        quantity: product.orderDetails.totalQuantity,
      })
    );
    mixpanel.track('Order Cancelled', {
      value: product.orderDetails.totalValue,
      paymentMode: 'Stripe',
      chargedID: product.draftCampaignId,
      product: product.productType,
      productID: product.orderDetails.products[0].productID,
      quantity: product.orderDetails.totalQuantity,
      platform: 'Web',
    });
  };

  userInitiatedProFeature = (product: StripeProductModel, feature: ProFeatureLabel) => {
    airbridge.events.send('airbridge.InitiateProFeature', {
      label: feature,
      action: 'Pro Feature Initiated',
      semanticAttributes: {
        totalValue: product.orderDetails.totalValue,
        totalQuantity: product.orderDetails.totalQuantity,
        currency: product.orderDetails.currency,
        products: product.orderDetails.products,
      },
    });
    mixpanel.track('Pro Feature Initiated', {
      label: feature,
      action: 'Pro Feature Initiated',
      totalValue: product.orderDetails.totalValue,
      totalQuantity: product.orderDetails.totalQuantity,
      currency: product.orderDetails.currency,
      products: product.orderDetails.products,
      platform: 'Web',
    });
  };

  userPublishProFeature = (product: StripeProductModel, feature: ProFeatureLabel) => {
    airbridge.events.send('airbridge.PublishProFeature', {
      label: feature,
      action: 'Pro Feature Published',
      semanticAttributes: {
        totalValue: product.orderDetails.totalValue,
        totalQuantity: product.orderDetails.totalQuantity,
        currency: product.orderDetails.currency,
        products: product.orderDetails.products,
      },
    });
    mixpanel.track('Pro Feature Published', {
      label: feature,
      action: 'Pro Feature Published',
      totalValue: product.orderDetails.totalValue,
      totalQuantity: product.orderDetails.totalQuantity,
      currency: product.orderDetails.currency,
      products: product.orderDetails.products,
      platform: 'Web',
    });
  };

  userCancelledProFeature = (product: StripeProductModel, feature: ProFeatureLabel) => {
    airbridge.events.send('airbridge.CancelProFeature', {
      label: feature,
      action: 'Pro Feature Cancelled',
      semanticAttributes: {
        totalValue: product.orderDetails.totalValue,
        totalQuantity: product.orderDetails.totalQuantity,
        currency: product.orderDetails.currency,
        products: product.orderDetails.products,
      },
    });
    mixpanel.track('Pro Feature Cancelled', {
      label: feature,
      action: 'Pro Feature Cancelled',
      totalValue: product.orderDetails.totalValue,
      totalQuantity: product.orderDetails.totalQuantity,
      currency: product.orderDetails.currency,
      products: product.orderDetails.products,
      platform: 'Web',
    });
  };

  customAirbridgeEvent = (eventName: string, data: CustomAirbridgeEventModel) => {
    airbridge.events.send(eventName, data);
  };

  static customGoogleEvent = (eventCategory: string, eventAction: string) => {
    ReactGA.send({
      hitType: 'event',
      eventCategory: eventCategory,
      eventAction: eventAction,
    });
  };

  customMixpanelEvent = (eventName: string, data: object) => {
    mixpanel.track(eventName, {
      isSubscribed: this.account.subscription?.isActive,
      subscriptionStatus: this.account.subscription?.status,
      subscriptionPlatform: this.account.subscription?.platform.name,
      subscriptionBillingType: this.account.subscription?.billingType,
      ...data,
      platform: 'Web',
    });
  };

  static customStaticMixpanelEvent = (eventName: string, data: object) => {
    mixpanel.track(eventName, {
      ...data,
    });
  };

  userOpenedPartnerPerk = (perk: PartnerPerk) => {
    airbridge.events.send('airbridge.openedPartnerPerk', {
      label: perk.title,
      action: 'Partner Perk Opened',
      semanticAttributes: {
        title: perk.title,
        content: perk.description,
      },
    });
    clevertap.event.push(
      'Partner Perk Opened',
      ClevertapJSONFormatter({
        title: perk.title,
        content: perk.description,
      })
    );
    mixpanel.track('Partner Perk Opened', {
      title: perk.title,
      content: perk.description,
      platform: 'Web',
    });
  };

  userClickedThroughPartnerPerk = (perk: PartnerPerk) => {
    airbridge.events.send('airbridge.clickedThroughPartnerPerk', {
      label: perk.title,
      action: 'Partner Perk Clicked Through',
      semanticAttributes: {
        title: perk.title,
        content: perk.description,
      },
    });
    clevertap.event.push(
      'Partner Perk Clicked Through',
      ClevertapJSONFormatter({
        title: perk.title,
        content: perk.description,
      })
    );
    mixpanel.track('Partner Perk Clicked Through', {
      title: perk.title,
      content: perk.description,
      platform: 'Web',
    });
  };

  userOpenedReleaseCycleTask = (task: ScheduleTaskModel, taskDate: string) => {
    airbridge.events.send('airbridge.taskOpened', {
      label: task.type,
      action: 'Task Opened',
      semanticAttributes: {
        category: task.category.name,
        title: task.title,
        content: task.body,
        date: taskDate,
      },
    });
    clevertap.event.push(
      'Task Opened',
      ClevertapJSONFormatter({
        category: task.category.name,
        title: task.title,
        content: task.body,
        type: task.type,
        date: taskDate,
      })
    );
    mixpanel.track('Task Opened', {
      category: task.category.name,
      title: task.title,
      content: task.body,
      type: task.type,
      date: taskDate,
      platform: 'Web',
    });
  };

  userClickedThroughReleaseCycleTask = (task: ScheduleTaskModel, taskDate: string) => {
    airbridge.events.send('airbridge.taskClickedThrough', {
      label: task.type,
      action: 'Task Clicked Through',
      semanticAttributes: {
        category: task.category.name,
        title: task.title,
        content: task.body,
        date: taskDate,
      },
    });
    clevertap.event.push(
      'Task Clicked Through',
      ClevertapJSONFormatter({
        category: task.category.name,
        title: task.title,
        content: task.body,
        type: task.type,
        date: taskDate,
      })
    );
    mixpanel.track('Task Clicked Through', {
      category: task.category.name,
      title: task.title,
      content: task.body,
      type: task.type,
      date: taskDate,
      platform: 'Web',
    });
  };

  userCompletedReleaseCycleTask = (task: ScheduleTaskModel, taskDate: string) => {
    airbridge.events.send('airbridge.taskCompleted', {
      label: task.type,
      action: 'Task Completed',
      semanticAttributes: {
        category: task.category.name,
        title: task.title,
        content: task.body,
        date: taskDate,
      },
    });
    clevertap.event.push(
      'Task Completed',
      ClevertapJSONFormatter({
        category: task.category.name,
        title: task.title,
        content: task.body,
        type: task.type,
        date: taskDate,
      })
    );
    mixpanel.track('Task Completed', {
      category: task.category.name,
      title: task.title,
      content: task.body,
      type: task.type,
      date: taskDate,
      platform: 'Web',
    });
  };

  userReactivatedReleaseCycleTask = (task: ScheduleTaskModel, taskDate: string) => {
    airbridge.events.send('airbridge.taskReactivated', {
      label: task.type,
      action: 'Task Reactivated',
      semanticAttributes: {
        category: task.category.name,
        title: task.title,
        content: task.body,
        date: taskDate,
      },
    });
    clevertap.event.push(
      'Task Reactivated',
      ClevertapJSONFormatter({
        category: task.category.name,
        title: task.title,
        content: task.body,
        type: task.type,
        date: taskDate,
      })
    );
    mixpanel.track('Task Reactivated', {
      category: task.category.name,
      title: task.title,
      content: task.body,
      type: task.type,
      date: taskDate,
      platform: 'Web',
    });
  };

  productViewed = ({
    product,
    productScreenName,
    goal,
    platforms,
    tracks,
    playlists,
  }: {
    product: string;
    productScreenName: string;
    goal?: string;
    platforms?: string[];
    tracks?: string[];
    playlists?: string[];
  }) => {
    clevertap.event.push(
      'Product Viewed',
      ClevertapJSONFormatter({
        product: product,
        productScreenName: `${product} - ${productScreenName}`,
        ...(goal && { goal: goal }),
        ...(platforms && { platforms: `${platforms.map((platform: string) => platform)}` }),
        ...(tracks && { tracks: `${tracks.map((track: string) => track)}` }),
        ...(playlists && { playlists: `${playlists.map((playlist: string) => playlist)}` }),
      })
    );
    mixpanel.track('Screen View', {
      Product: product,
      Type: product,
      Screen: `${product} - ${productScreenName}`,
      ...(goal && { goal: goal }),
      ...(platforms && { platforms: `${platforms.map((platform: string) => platform)}` }),
      ...(tracks && { tracks: `${tracks.map((track: string) => track)}` }),
      ...(playlists && { playlists: `${playlists.map((playlist: string) => playlist)}` }),
      isSubscribed: this.account.subscription?.isActive || false,
      subscriptionStatus: this.account.subscription?.status,
      subscriptionPlatform: this.account.subscription?.platform?.name,
      subscriptionBillingType: this.account.subscription?.billingType,
      Platform: 'Web',
    });
  };

  static onboardingProductViewed = ({
    product,
    productScreenName,
    promoteChoice,
  }: {
    product: string;
    productScreenName: string;
    promoteChoice?: string;
  }) => {
    clevertap.event.push(
      'Product Viewed',
      ClevertapJSONFormatter({
        product: product,
        productScreenName: `${product} - ${productScreenName}`,
        ...(promoteChoice && { promoteChoice: promoteChoice }),
      })
    );
    mixpanel.track('Screen View', {
      Product: product,
      Type: product,
      Screen: `${product} - ${productScreenName}`,
      ...(promoteChoice && { promoteChoice: promoteChoice }),
      Platform: 'Web',
    });
  };

  productExited = ({
    product,
    productScreenName,
    goal,
    platforms,
    tracks,
    playlists,
  }: {
    product: string;
    productScreenName: string;
    goal?: string;
    platforms?: string[];
    tracks?: string[];
    playlists?: string[];
  }) => {
    clevertap.event.push(
      'Product Exited',
      ClevertapJSONFormatter({
        product: product,
        'Product Screen Name': `${product} - ${productScreenName}`,
        ...(goal && { goal: goal }),
        ...(platforms && { platforms: `${platforms.map((platform: string) => platform)}` }),
        ...(tracks && { tracks: `${tracks.map((track: string) => track)}` }),
        ...(playlists && { playlists: `${playlists.map((playlist: string) => playlist)}` }),
      })
    );
    mixpanel.track('Product Exited', {
      Product: product,
      Type: product,
      Screen: `${product} - ${productScreenName}`,
      ...(goal && { goal: goal }),
      ...(platforms && { platforms: `${platforms.map((platform: string) => platform)}` }),
      ...(tracks && { tracks: `${tracks.map((track: string) => track)}` }),
      ...(playlists && { playlists: `${playlists.map((playlist: string) => playlist)}` }),
      isSubscribed: this.account.subscription?.isActive || false,
      subscriptionStatus: this.account.subscription?.status,
      subscriptionPlatform: this.account.subscription?.platform?.name,
      subscriptionBillingType: this.account.subscription?.billingType,
      Platform: 'Web',
    });
  };

  benchmarkInsightsViewed = (platform: string, metric: string, data?: string | number) => {
    clevertap.event.push(
      'Benchmark Insights Viewed',
      ClevertapJSONFormatter({
        platform: platform,
        metric: metric,
        data: data,
      })
    );
    mixpanel.track('Benchmark Insights Viewed', {
      platform: platform,
      metric: metric,
      data: data,
    });
  };
}

export default UserTracking;
