import { useCallback, useEffect, useState } from 'react';

import { usePostHog } from 'posthog-js/react';

import { FeatureFlagNameFormatter } from '@/constants/FeatureFlagEvents';

const useFeatureFlags = () => {
  const posthog = usePostHog();
  const [surveys, setSurveys] = useState<unknown | null>(null);

  const identifyUser = useCallback(
    (userId: string) => {
      posthog.identify(userId);
    },
    [posthog]
  );

  const captureEvent = useCallback(
    (eventName: string, data?: object) => {
      posthog.capture(eventName, data);
    },
    [posthog]
  );

  const getFeatureFlag = useCallback(
    (featureFlag: string) => {
      return posthog.getFeatureFlag(featureFlag);
    },
    [posthog]
  );

  const getFeatureFlagPayload = useCallback(
    (featureFlag: string) => {
      return posthog.getFeatureFlagPayload(featureFlag);
    },
    [posthog]
  );

  const getSurveys = useCallback(async () => {
    return await posthog.getActiveMatchingSurveys((surveys) => {
      setSurveys(surveys);
      return surveys;
    }, true);
  }, [posthog]);

  const getDistinctId = useCallback(() => {
    return posthog.get_distinct_id();
  }, [posthog]);

  useEffect(() => {
    getSurveys();
  }, [getSurveys]);

  return {
    isPlaylistingDisabled: posthog.isFeatureEnabled('isPlaylistingDisabled'),
    isFanHubDisabled: posthog.isFeatureEnabled('isFanHubDisabled'),
    isMetaAdsDisabled: posthog.isFeatureEnabled('isMetaAdsDisabled'),
    isTikTokAdsDisabled: posthog.isFeatureEnabled('isTikTokAdsDisabled'),
    isPricingTestGroup: getFeatureFlag(FeatureFlagNameFormatter('isVariablePricingEnabled')) === 'test_group_lower',
    isHomeScreenBannerEnabled: posthog.isFeatureEnabled(FeatureFlagNameFormatter('homeScreenBanner')),
    isPreviousPlaylistsBannerEnabled: posthog.isFeatureEnabled(FeatureFlagNameFormatter('previousPlaylistsBanner')),
    isWebGenreEmbeddedRecsEnabled: posthog.isFeatureEnabled(FeatureFlagNameFormatter('isWebGenreEmbeddedRecsEnabled')),

    identifyUser: identifyUser,
    captureEvent: captureEvent,
    getFeatureFlag: getFeatureFlag,
    getFeatureFlagPayload: getFeatureFlagPayload,
    getSurveys: getSurveys,
    surveys: surveys,
    getDistinctId: getDistinctId,
  };
};

export default useFeatureFlags;
